import React from "react";
import Education from "../about/Education";
import Industries from "./Industries";
import References from "./References";


const index = () => {
    return (
      <section className="main-content ">
        <div className="container">

          {/* Experience & Education Starts */}
        
              <div className="resume-box">
              <Education />
              </div>
           
         
          {/*  Experience & Education Ends */}
          <hr className="separator" />
          <div className="row">
          <div className="col-12 ">
            <h3 className="text-uppercase mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
            Iparágak
            </h3>
            <Industries />
          </div>
        
        </div>
        <hr className="separator" />
        <div className="row">
          <div className="col-12 ">
            <h3 className="text-uppercase mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
            Referenciák
            </h3>
            <References />
          </div>
        
        </div>

        </div>
      </section>
    );
  };
  
  export default index;