import React from "react";

const achievementsContent = [
  { title: "15", class:"", subTitle1: "év", subTitle2: "tapasztalat" },
  { title: "550",class:"", subTitle1: "lezárt", subTitle2: "beszámoló" },
  { title: "340",class:"no-margin-bottom", subTitle1: "könyvvizsgálói", subTitle2: "jelentés" },
  { title: "280",class:"no-margin-bottom", subTitle1: "elégedett", subTitle2: "ügyfél" },
];

const Achievements = () => {
  return (
    <div className="row">
      {achievementsContent.map((val, i) => (
        <div className="col-6" key={i}>
          <div className={`box-stats with-margin ${val.class}`}>
            <h3 className="poppins-font position-relative">{val.title}</h3>
            <p className="open-sans-font m-0 position-relative text-uppercase">
              {val.subTitle1} <span className="d-block">{val.subTitle2}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
