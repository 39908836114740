import React, { useState } from "react";



const BlogLight = () => {


  return (
    <>
    
     
        
      <div
          className="col-lg-12 text-center coming-soon-bg"
        >
       <h1 className="coming-soon">COMING <span>SOON</span></h1>
        </div>

      
     
    </>
  );
};

export default BlogLight;
