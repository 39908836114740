import React from "react";


const  References = () => {
  return (
    
      
    
    
    
              <div >
                 <img
              src= "img/refrences/2_row.png"
              className="img-fluid d-none d-lg-block"
              alt="refrence-picture"
            />
             <img
              src= "img/refrences/3_row.png"
              className="img-fluid d-none d-md-block  d-lg-none"
              alt="mobile-picture"
            />

<img
              src= "img/refrences/12-logos_mobil_768.png"
              className="img-fluid d-block d-md-none d-lg-none"
              alt="mobile-picture"
            />

            
    
    </div> 
    
   
  );
  
};

export default  References;