import React from "react";

const educationContent = [
  {
    year:  ["Éves választott könyvvizsgálat",  "Átalakulások könyvvizsgálata","Apport hitelesítés","Átvilágítás" ], 
    degree: "KÖNYVVIZSGÁLAT",
    icon:"search_mod",
    class:"no-margin-top",
    details: `A könyvvizsgálat cégünk egyik fő tevékenysége. Szakértelmünknek, precizitásunknak és elhivatottságunknak köszönhetően biztosítjuk, hogy megbízható, objektív és magas szakmai színvonalú könyvvizsgálatot folytatunk le, melyből ügyfeleink, a tulajdonosok, valamint üzleti partnereik is egyaránt profitálnak. Csapatunk megfelelő tapasztalattal rendelkezik, komplex könyvvizsgálati szolgáltatások nyújtásához, így számíthat szaktudásunkra pénzügyi beszámoló éves könyvvizsgálatánál, átalakulás könyvvizsgálatánál, apport hitelesítéseknél, valamint átvilágításnál egyaránt. `,
  },
  {
    year:  ["Könyvelési szolgáltatás",  "Bérszámfejtés","Kontrolling támogatás","Ügyviteli szolgáltatások" ], 
    degree: "Könyviteli szolgáltatások",
    icon:"ballance_mod",
    class:"",
    details: `Cégünk széles körben nyújt számviteli, könyvelési szolgáltatásokat ügyfelei részére. Szakértő kollégáink segítenek Önnek eligazodni a könyvelés, bérszámfejtés, adóbevallások és a határidők bonyolult rendszerében a gazdasági tevékenység megkezdésétől egészen annak lezárásáig. Segítséget tudunk Önnek nyújtani különleges céghelyzetek felmerülése esetén is, mint például az átalakulás, összeolvadás, vagy a végelszámolás. Könyvviteli szolgáltatásainkhoz elektronikus támogatást nyújtunk. Magas adatbiztonságú webes ügyfélportálon keresztül ügyfeleinknek folyamatos hozzáférést biztosítunk cégük nyomon követéséhez, folyamataik állapotának monitorozásához, ami kényelmesebb, gyorsabb, környezettudatosabb  ügyfél kommunikációt eredményez Önnek is.`,
  },
  {
    year:  ["Számviteli tanácsadás",  "Üzletviteli tanácsadás","Tranzakciós szolgáltatások (M&A)","Folyamat optimalizálások" ], 
    degree: "Üzleti és számviteli tanácsadás",
    icon:"users_mod",
    class:"no_lg_margin",
    details: `Egyes gazdasági események könyvelése és azok beszámolási következményei egyre összetettebb feladattá váltak. Számviteli szakembereink segítséget nyújtanak olyan komplex és speciális szakértelmet igénylő kérdésekben, amelyek megválaszolásához szükséges  tapasztalat, vagy szakértelem nem feltétlenül állnak rendelkezésre egy adott szervezeten belül. Innovatív vállalati útkeresés támogatásától egészen az iparág-specifikus problémák kezeléséig, szakértőink az ügyfelekkel együttműködve dolgoznak ki megoldásokat a kockázatok kezelésére, hatékonyság növelésére, jogszabályi megfelelés erősítésére.`,
  },
  {
    year:  ["Adótanácsadás","Transzferár tanácsadás","Expat szolgáltatások","Képviselet adóhatósági ellenőrzéseken" ], 
    degree: "Adótanácsadás",
    icon:"funnel_dollar_mod",
    class:"no-margin-bottom",
    details: `Az adózás területén elengedhetetlen az adózási színtér folyamatos követése, az aktuális szabályok naprakész ismerete. Legyen szó kisebb magánvállalkozásról, vagy cégcsoportot érintő adózási kérdésről, számíthat  szakértelmünkre. A folyamatosan változó adórendszer követése jelentős kihívás elé állítja a Társaságokat. Adótervezésben jártas szakértőink segítséget tudnak nyújtani Önnek, hogy cége adóstratégiája, adóadminisztrációs folyamatai összhangba kerüljenek a cég valós működésével, tulajdonosok céljaival. Ne feledje a megfelelő adótervezéssel elkerülhet fölösleges kockázatokat, valamint jelentős adómegtakarítást érhet el, mely forrástöbblet új lehetőségeket teremthet cége számára.`,
  },
]
const Education = () => {
  return (
    <div class="row ">
      {educationContent.map((val, i) => (
        <div class="col-lg-6  col-sm-12">

        <ul className="d-none d-lg-block">
        <div className="service-icons-div row">
            <div className="col-lg-2 col-2 col-md-1 col-sm-1">
        <img
              src={`img/${val.icon}.svg`}
              className="services-icons"
              alt={`${val.degree}`}
            /> 
            </div>
              <div className="col-lg-10 col-md-11 col-sm-11 col-10">
              <h5 className="poppins-font text-uppercase">
            {val.degree}
            <hr className="services-line green-text margin-null"/>
          </h5>
              </div>
              </div>
        <li class= {`services-hack ${val.class}`} key={i}>
          
            
              
            
         
         
          
          <p className="open-sans-font justify padding-one-row">{val.details}</p>
         
          {val.year.map((val, i) => (
         <div class="row services-fix">   
         <div class="square col-1"> </div> <div class="col-11"><p> {val}</p></div>
         </div>
          ))}
         
        </li>
        </ul>
        <div   className={`mobile-div d-lg-none d-block ${val.class}`}>
     
          
        
            
              <div className="col-lg-12 row">
              <div className="col-2">
              <img
              src={`img/${val.icon}.svg`}
              className="services-icons"
              alt={`${val.degree}`}
            /> 
            </div>
            <div className="col-10">
              <h5 className="poppins-font text-uppercase mobile-services">
            {val.degree}
           
          </h5>
          </div>
              </div>
              <hr className="services-line green-text margin-null"/>
              
         
          
          <p className="open-sans-font justify padding-one-row">{val.details}</p>
         
          {val.year.map((val, i) => (
         <div class="row  services-fix">   
         <div class="square col-1"> </div> <div class="col-11"><p> {val}</p></div>
         </div>
         
          ))}
         
        
    </div>
    </div>
      ))}
    </div>
  );
  
};

export default Education;
