import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map-marker-alt green-icons position-absolute"></i>
        <span className="d-block">Budapesti Iroda: </span>
         Angyalföldi út 5/b. II.emelet 208. iroda<br />
         1134 Budapest
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map-marker-alt green-icons position-absolute"></i>
        <span className="d-block">Szarvasi iroda: </span> Szabadság út 22.<br />
        5540 Szarvas
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open green-icons position-absolute"></i>
        <span className="d-block">email: </span>{" "}
        <a href="mailto:info@dyar.hu"> 	info@dyar.hu</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square green-icons position-absolute"></i>
        <span className="d-block">Telefon:</span>{" "}
        <a href="Tel: +36300130511">+36300130511</a>
      </p>
      {/* End .custom-span-contact */}
      <p className="open-sans-font custom-span-contact position-relative last-row-in-adress">
        <i className="fab fa-linkedin green-icons position-absolute"></i>
        <span className="d-block">Social:</span>{" "}
        <a href="https://linkedin.com">LinkedIn</a>
      </p>
     
    </>
  );
};

export default Address;
