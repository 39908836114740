import React from "react";

const stuffContent = [
  {name: "Szebedinszky Péter",img: "Szebedinszky-Peter.jpg" },
  {name: "Ádám Arnold",img: "Adam_Arnold.jpg" },
  {name: "Arányi Balázs",img: "Aranyi_Balazs.jpg" },
  {name: "Baginé Molnár Ágnes",img: "Bagine_Molnar_Agnes.jpg" },
  {name: "Balla László",img: "Balla_Laszlo.jpg" },
  {name: "Dr. Pákozdi Edit",img: "Pakozdi_Edit.jpg" },
  {name: "Izbéki Kitty",img: "Izbeki_Kitty.jpg" },
  {name: "Matuzné Danó Laura",img: "Matuzne_Dano_Laura.jpg" },
  {name: "Németh Szabó Szilvia",img: "Nemeth_Szabo_szilvia.jpg" },
  {name: "Némethné S. Adrienn",img: "Nemethne_Salamon_Adrienn.jpg" },
  {name: "Oláh Tamás",img: "Olah_Tamas.jpg" },
  {name: "Őri Eszter",img: "Ori_Eszter.jpg" },
  {name: "Pintér Gábor",img: "Pinter_Gabor.jpg" },
  {name: "Varga Ildikó",img: "Varga_Ildiko.jpg" },
  {name: "Vigh Donát",img: "Vigh_Donat.jpg" }
 
];


const Stuff = () => {
  return (
    <div className="col-12">
      <div className="row">
      
    
   
      {stuffContent.map((val, i) => (
    
          <div  className="col-2 mr-2  d-none  d-lg-block stuff-padding">
          <img
              src={`img/stuff/${val.img}`}
              className="img-fluid main-img-stuff d-sm-block "
              alt={`${val.name}`}
            />
            <h6 class="poppins-font text-uppercase text-center"> {val.name} </h6>
            </div>
           
          
         
      ))}
          {stuffContent.map((val, i) => (
    
    <div  className="col-4 d-lg-none stuff-padding">
    <img
        src={`img/stuff/${val.img}`}
        className="img-fluid main-img-stuff d-sm-block "
        alt="hero man"
      />
      <h6 class="poppins-font text-uppercase text-center  collegak"> {val.name} </h6>
      </div>
     
    
   
))}
    </div>
    </div>
  );
};

export default Stuff;
