import React from "react";

const IndustriesContect = [
  {
    name:  "Ingatlan", 
    icon:"hotel",
    details: `Egy évtizede nyújtunk folyamatos könyvviteli és egyedi tranzakciós szolgáltatásokat ingatlan szektorban tevékenykedő vállalatoknak. Az ágazatra jellemző speciális áfa szabályok, közbeszerzések, jótállási és garanciális kötelezettségek igénylik a tapasztalt szaktudást.`,
  },
  {
    name:  "Technológia", 
    icon:"wifi",
    details: `Szakértőink jelentős tapasztalattal rendelkeznek technológiai cégek könyvvizsgálatában, tranzakciós M&A szolgáltatások nyújtásában, valamint startupok könyvvitelének, ügyvitelének biztosításában. `,
  },
  {
    name:  "Kereskedelem", 
    icon:"handshake",
    details: `Testreszabott megoldásokat szállítunk kereskedelmi cégek készletezéssel, nemzetközi piacra lépéssel kapcsolatos adó-, számviteli kérdéseire. Könyvviteli szolgáltatásunk keretében segítséget nyújtunk a folyamatosan változó jogszabályi környezetnek való megfelelésben.`,
  },
  {
    name:  "Egészségügy", 
    icon:"first-aid",
    details: `Ismereteinknek és gyakorlati tapasztalatunknak köszönhetően hatékony megoldást tudunk kínálni gyógyszergyártóknak, gyógyszerforgalmazóknak, egészségügyi szolgáltatóknak, gyógyászati műszereket, berendezéseket gyártó és értékesítő cégeknek egyaránt.`,
  },
  {
    name:  "Jogi szolgáltatók", 
    icon:"user-graduate",
    details: `Szakképzett munkatársaink, cégünk megalapítása óta nyújtanak pontos, precíz, a törvényi előírásoknak maradéktalanul megfelelő könyvviteli, könyvvizsgálati szolgáltatásokat közjegyzőknek, ügyvédi irodáknak.`,
  },
  {
    name:  "Mezőgazdaság", 
    icon:"tractor",
    details: `Szakértő csapatunk ismeri az agrárágazat specialitásait. A termelés, a támogatások igénybevétele és elszámolása, valamint a környezetvédelmi feladatok könyvvizsgálatában, könyvvitelében számíthat ránk. `,
  }
]
const Industries = () => {
  return (
    <div className="row">
      {IndustriesContect.map((val, i) => (
        <div className="col-lg-4 col-sm-6 mt-4">
        
          <div className="row">
          <div className="col-2 industries-icon  ">
            <i className={`fa fa-${val.icon}`}></i>
            </div>
            <div className="col-8">
            <h5 className="poppins-font text-uppercase row-hack industries-name">
            {val.name}
            
          </h5>
          </div>
          </div>
         
          
          <p className="open-sans-font justify no-margin-bottom">{val.details}</p>
        
    </div>
      ))}
    </div>
  );
  
};

export default Industries;