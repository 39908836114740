import React from "react";

const skillsContent = [
  {buzword: "Megbízhatóság",contect: "Cégünk 2006 óta nyújt szakértő szolgáltatásokat ügyfeleinek. Üzletfeleink jelentős részét működésünk kezdetétől támogatjuk munkánkkal." },
  {buzword: "Tapasztalat",contect: "Szakértőink nemzetközi tanácsadó cégeknél és hazai középvállalatoknál edződött szakemberek, akik a cégen belüli tudásmegosztással egy rendkívül magas szintű szakmai tudásbázist képviselnek." },
  {buzword: "Szakértelem",contect: "Széleskörű szakértelem egy helyen. Legyen szó könyvvizsgálatról, adótanácsadásról, átvilágításról, könyvelésről vagy bérszámfejtésről, szakértőinkre mindenben számíthat." },
  {buzword: "Kommunikáció",contect: "Csapatunk tagjai fiatal, jó kommunikációs készséggel rendelkező, ügyfélcentrikus szakemberek, akikkel gördülékenyen folyhat a munka." },
 
];

const Skills = () => {
  return (
    <div class="col-12">
      <div class="row">
       <div class="col-2">
       </div>
    
    <div className="col-lg-8 col-sm-12 about-us-background">
      {skillsContent.map((val, i) => (
        <div className="col-12 choose " key={i}>
          <div class="row d-none d-lg-flex">
          <div  className="col-1">
          <div className={`c100 green-bg  ${val.skillClass}`}>
            <span><i className="fa fa-check dyar-orange" aria-hidden="true"> </i></span>
            
          </div>
          
          </div>
          <div className="col-11">
          <h5 class="poppins-font text-uppercase gray-text">{val.buzword}</h5>
          <p class="justify">{val.contect}</p>

          </div>
          </div>

          <div class="row d-flex d-lg-none">
          <div  className="col-2">
          <div className={`c100  green-bg  ${val.skillClass}`}>
            <span><i className="fa fa-check dyar-orange" aria-hidden="true"> </i></span>
          </div>
          </div>
          <div className="col-8">
          <h5 class="poppins-font text-uppercase about-us-margin">{val.buzword}</h5>
          </div>
          
          <div className="col-12">
          <p class="justify">{val.contect}</p>

          </div>
          </div>
        </div>
        
      ))}
    </div>
    </div>
    </div>
  );
};

export default Skills;
