import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hero from "../../components/hero/HeroLight";
import Index from "../../components/about/index";
import Address from "../../components/Address";
import Services from "../../components/services/index";
import Blog from "../../components/blog/BlogLight";
import Contact from "../../components/Contact";
import { ScrollingProvider,Section} from 'react-scroll-section';
import Scrollspy from 'react-scrollspy';
import '@fortawesome/fontawesome-free/js/all.js';




const menuItem = [
  { icon: "fa-home", menuName: "Főoldal" },
  { icon: "fa-user", menuName: "Magunkról" },
  { icon: "fa-briefcase", menuName: "Megoldásaink" },
  { icon: "fa-envelope-open", menuName: "Kapcsolat" },
  { icon: "fa-laptop", menuName: "Ügyfélportál" },
];
const secttions=[
  'Főoldal', 
  'Magunkról', 
  'Megoldásaink',
  'Kapcsolat',
  'Ügyfélportál']


const HomeLight = () => {
  document.body.classList.add("light");
  const [hash, setHash] = React.useState(window.location.hash);
  console.log(hash);
  console.log(setHash);
 
  return (
    <ScrollingProvider>
      

    
    <div className="green">
      <div className="demo-sticker dark-grey-bg">
        <a href="/home-dark">
          <i className="fas fa-globe-europe" ></i>
        </a>
      </div>

        <div className="header">
          <TabList className="icon-menu  revealator-slideup revealator-once revealator-delay1">
         
          <Scrollspy items={secttions} currentClassName="react-tabs__tab--selected">
            {menuItem.map((item, i) => (
            
            <a href={`#${item.menuName}`}>
              <Tab Onclick={`#${item.menuName}`} className="icon-box" key={i} >
                 
                <i className={`fas ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
                
              </Tab>
              
              </a>
            ))}
            </Scrollspy>
           
          </TabList>
        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">

          {/* Hero Content Starts */}

          <Section id="Főoldal" className="mobile_margin">
          <div  className="home ">
            
            <div
              className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="color-block d-none d-lg-block"></div>
              <Hero />
            </div>
          </div>
          </Section>
          {/* Hero Content Ends */}

          {/* About Content Starts */}
          <Section id="Magunkról"  className="mobile_margin"  >
          <div  className="about">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section  text-left text-sm-center">
                <h1>
                  Szakértő <span>csapat</span>
                </h1>
                <span className="title-bg">Magunkról</span>
              </div>
              {/* End title */}
              <Index />
            </div>
          </div>
          </Section>
          {/* About Content Ends */}

          {/* Portfolio Content Starts */}
          <Section id="Megoldásaink"  className="mobile_margin"  >
          <div className="about">
            <div
              className="title-section row text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
             
              <h1 className="row-hack ">
               Miben <span>segíthetünk?</span>
              </h1>
              <span className="title-bg">megoldásaink </span>
              
            </div>
            {/* End title */}
            <div
              className="container grid-gallery main-content"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <Services />
            </div>
            {/* End grid gallery */}
          </div>
          </Section>
          {/* Portfolio Content Ends */}

          {/* Contact Content Starts */}
          <Section  id="Kapcsolat"  className="mobile_margin" >
          <div className="contact">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
               Keressen <span>Minket!</span>
              </h1>
              <span className="title-bg">Kapcsolat</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="row">
                {/*  Left Side Starts */}
                <div className="col-12 col-lg-4">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                  KÉRJEN AJÁNLATOT!
                  </h3>
                  <p className="open-sans-font mb-4">
                  Forduljon hozzánk bizalommal! Vegye fel velünk a kapcsolatot az alábbi elérhetőségeken!
                  </p>
                  <Address />
                  {/* End Address */}
                </div>
                {/* Left Side Ends */}

                {/*  Contact Form Starts  */}
                <div className="col-12 col-lg-8">
                  <Contact />
                </div>
                {/*  Contact Form Ends */}
              </div>
            </div>
            {/* End .container */}
          </div>
          </Section>
          {/* Contact Content Ends */}

          {/* Blog Content Starts */}
          <Section id="Ügyfélportál"  className="mobile_margin">
          <div className="blog">
            <div
              className="title-section text-left text-sm-center "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                webes <span>megoldásaink</span>
              </h1>
              <span className="title-bg">Ügyfélportál</span>
            </div>
            <div
              className="container"
              data-aos="fade-in"
              data-aos-duration="1200"
            >
              
            
                <Blog />
             
              
            </div>
          </div>
          </Section>
          {/* Blog Content Ends */}
        </div>
     
    </div>
    </ScrollingProvider>
  );
};
export default HomeLight;
