import React from "react";



const PersonalInfo = () => {
  return (
    <p class="justify no-margin-bottom">
    A DYAR egyenlő az azt alkotó szakemberek összességével, akik 15 éve magas színvonalú, testre szabott szolgáltatásokat nyújtanak a könyvvizsgálat, könyvvitel, valamint az adó-, számviteli és üzleti tanácsadás területén. Csapatunk tagjai olyan sikerorientált, fiatal szakemberek, akik szakmai tapasztalataikat nemzetközi tanácsadó cégeknél, valamint hazai középvállalatoknál szerezték. Célunk, hogy szaktudásunk és széleskörű tapasztalatunk révén olyan minőségi, hozzáadott értéket biztosító szolgáltatásokat nyújtsunk ügyfeleink számára, melyek támogatják üzleti céljaik megvalósítását, illetve azonnali megoldást kínálnak felmerülő problémáikra.  Középpontban a naprakész szakértelem és a magas szintű ügyfélközpontúság áll. Munkánk során kiemelt figyelmet szentelünk a személyes kapcsolattartásra, mert hisszük, hogy ügyfeleink igényeit hatékonyan megérteni, eredményesen kiszolgálni így lehet.
      </p>
  );
};

export default PersonalInfo;
